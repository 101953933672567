import React, { Fragment, useState } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { SRLWrapper } from "simple-react-lightbox";
import Seo from "../components/seo"
import { FaArrowLeft, FaChevronLeft, FaChevronUp } from 'react-icons/fa';
const { htmlToText } = require('html-to-text');


export default function Single({ data }) {
  const page = data.allWpPortfolioCategory
  const [readMore,setReadMore] = useState(false);
  const options = {
    buttons: {
      showDownloadButton: false,
    }
  }
  return (
    <Layout>
      <Seo 
          title={page.nodes[0].name}
          description={page.nodes[0].description}
          image={page.nodes[0].extra.thumbnail.mediaItemUrl}
      />
      <div>
        <div className="row ptb--30">
          <div style={{width:'auto'}}>
            <button style={{backgroundColor:'transparent',border:'none',textAlign:'left',paddingBottom:'10px'}} onClick={() => navigate(-1)}>
              <FaArrowLeft/>
            </button>
          </div>
          <h1 style={{marginBottom:'0',width:'auto',position:'absolute',left:'50%',transform:'translateX(-50%)'}}>
            {page.nodes[0].name}
          </h1>
        </div>
        {page.nodes[0].extra.shortDescription ?
        <Fragment>
          {readMore ?
            <Fragment>
            <div className="noborder" dangerouslySetInnerHTML={{ __html: page.nodes[0].extra.fullDescription }}/>
            <div>
              <FaChevronUp style={{opacity:'.6',fontSize:'10px'}}/>&nbsp;<button className="readmore" onClick={()=>{setReadMore(!readMore)}}>Read Less...</button>
            </div>
          </Fragment>
          :
          <Fragment>
              <div className="noborder" dangerouslySetInnerHTML={{ __html: page.nodes[0].extra.shortDescription }} />
              <div>
                <FaChevronLeft style={{opacity:'.6',fontSize:'10px'}}/>&nbsp;<button className="readmore" onClick={()=>{setReadMore(!readMore)}}>Read More...</button>
              </div>
            </Fragment>
        }
        </Fragment>
        :
        <p className="pb--50">{page.nodes[0].description}</p>
        }
      </div>
      <div className="pb--50">
      {page.nodes.map((node, i) => (
          <div key={i}>
              <SRLWrapper options={options}>
              {node.portfolioItems.nodes.filter(x => x.extra.hide === null).map((node, i) =>(
                node.extra.gallery !== null) ?
                <Fragment>
                  <GatsbyImage
                    className="col-12 col-md-3" 
                    style={{margin:'0 auto',cursor:'pointer',objectFit:'cover',height:'200px',boxSizing:'border-box',border:"1px solid white"}}
                    image={node.extra.gallery[0].localFile.childImageSharp.gatsbyImageData}
                    alt={node.content ? htmlToText(node.content) : node.title}
                    srl_gallery_image="true"
                    key={i}
                  />
                </Fragment>
                  : null
              )}
              </SRLWrapper>
          </div>
      ))}      
      </div> 
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    allWpPortfolioCategory(
      filter: {slug: {eq: $slug}}
      ) {
      nodes {
        wpParent {
          node {
            name
            slug
          }
        }
        extra {
          shortDescription
          fullDescription
          thumbnail {
            mediaItemUrl
          }
        }
        name
        slug
        description
        portfolioItems {
          nodes {
            title
            slug
            uri
            content
            extra{
              hide
              gallery{
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 600,
                      placeholder: DOMINANT_COLOR,
                      formats: AUTO,
                      quality: 100
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`